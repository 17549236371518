import React from 'react'

interface SeoProps {
  title: string
  lang: string
  langs: string[]
  siteUrl: string
  pagePath: string
  ogType?: 'website' | 'article'
  ogDescription: string
  ogImgPath: string
  children?: React.ReactNode
}

export const Seo = ({
  title,
  lang,
  langs,
  siteUrl,
  pagePath,
  ogType = 'article',
  ogDescription,
  ogImgPath,
  children
}: SeoProps) => {
  const siteName = 'デジタル庁 ウェブサービス・アプリケーション'

  // ogpImgPathは絶対パスを指定すること
  const ogImgUrl = new URL(ogImgPath, siteUrl).href
  const url = new URL(pagePath, siteUrl).href
  const originalPath = pagePath.replace(
    /\/(en|ja|ko|zh-cmn-hans|zh-cmn-hant)?\//,
    '/'
  )
  const locales: { [key: string]: string } = {
    ja: 'ja_JP',
    en: 'en_US',
    ko: 'ko_KR',
    'zh-cmn-hans': 'zh_CN',
    'zh-cmn-hant': 'zh_TW'
  }

  const getPath = (path: string, lang: string) => {
    // デフォルト言語
    if (lang === 'ja') {
      return path
    }
    return `/${lang}${path}`
  }

  return (
    <>
      <html lang={lang} />
      <link rel="canonical" href={url} />
      {langs.map((lang) => (
        <React.Fragment key={Math.random()}>
          <link
            rel="alternate"
            hrefLang={lang}
            href={new URL(getPath(originalPath, lang), siteUrl).href}
          />
          <meta property="og:locale:alternate" content={locales[lang]} />
        </React.Fragment>
      ))}
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={ogDescription} />
      <meta name="author" content="デジタル庁" />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={ogImgUrl} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="675" />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:site" content="@digital_jpn" />
      <meta property="apple-mobile-web-app-title" content={siteName} />
      <meta property="MobileOptimized" content="width" />
      <meta property="HandheldFriendly" content="true" />
      {children}
    </>
  )
}
