import React from 'react'
import type { GatsbySSR, RenderBodyArgs } from 'gatsby'
import { I18nextProvider } from 'react-i18next'
import { PageContextProvider, i18nConfig } from 'component'

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }) => {
  return <I18nextProvider i18n={i18nConfig}>{element}</I18nextProvider>
}

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({
  element,
  props
}) => {
  return (
    <PageContextProvider value={props.pageContext}>
      {element}
    </PageContextProvider>
  )
}

const headComponents = [
  <link
    key={0}
    href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700&display=swap"
    rel="stylesheet"
  />,
  <link
    key={1}
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:wght@100;200;300;400;500;600;700&display=swap"
    rel="stylesheet"
  />
]

export const onRenderBody = ({ setHeadComponents }: RenderBodyArgs) => {
  setHeadComponents(headComponents)
}
