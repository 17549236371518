import React from 'react'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  var _uic: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  var _uih: any
}

export const UserInsight = () => {
  React.useEffect(() => {
    const script = document.createElement('script')
    script.src = '//cs.nakanohito.jp/b3/bi.js'
    script.async = true
    document.body.appendChild(script)

    globalThis._uic = window._uic || {}
    globalThis._uih = window._uih || {
      id: process.env.USER_INSIGHT_ID,
      lg_id: '',
      fb_id: '',
      tw_id: '',
      uigr_1: '',
      uigr_2: '',
      uigr_3: '',
      uigr_4: '',
      uigr_5: '',
      uigr_6: '',
      uigr_7: '',
      uigr_8: '',
      uigr_9: '',
      uigr_10: '',
      uls: 1,
      security_type: -1
    }
  }, [])

  return <></>
}
