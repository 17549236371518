import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'

export type Props = React.ComponentProps<'div'>

export const FooterLogo = (props: Props) => {
  const { className, ...newProps } = props

  const { t } = useTranslation()

  return (
    <div className={clsx(className)} {...newProps}>
      <figure className="text-center">
        <a href="https://www.digital.go.jp/">
          <img
            src={t('global.logoPath')}
            alt="デジタル庁"
            className="mx-auto"
            width="98"
          />
        </a>
        <figcaption className="mt-2 text-small text-sumi-600">
          &copy; Digital Agency, Government of Japan
        </figcaption>
      </figure>
      <div
        className="h-36"
        style={{
          background:
            'linear-gradient(180deg, #FFFFFF 0.71%, #FFFFFF 5.59%, #F5F7FD 13.39%, #DDE3F7 25.1%, #B5C3EF 40.71%, #7E96E3 58.28%, #385ED4 78.77%, #0031C9 93.4%, #0020BC 98.28%)'
        }}
      />
    </div>
  )
}
