const localMap: { [key: string]: string } = {
  'zh-cmn-hant': 'zh-hant',
  'zh-cmn-hans': 'zh-hans'
}

export const formatDate = (dateStr: string, lang: string) => {
  const date = dateStr ? new Date(dateStr) : new Date()
  const locale = localMap[lang] ?? lang

  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }).format(date)
}
