exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-service-news-page-tsx": () => import("./../../../src/templates/ServiceNewsPage.tsx" /* webpackChunkName: "component---src-templates-service-news-page-tsx" */),
  "component---src-templates-service-news-top-tsx": () => import("./../../../src/templates/ServiceNewsTop.tsx" /* webpackChunkName: "component---src-templates-service-news-top-tsx" */),
  "component---src-templates-service-page-lv-1-tsx": () => import("./../../../src/templates/ServicePageLv1.tsx" /* webpackChunkName: "component---src-templates-service-page-lv-1-tsx" */),
  "component---src-templates-service-page-lv-2-tsx": () => import("./../../../src/templates/ServicePageLv2.tsx" /* webpackChunkName: "component---src-templates-service-page-lv-2-tsx" */),
  "component---src-templates-service-top-tsx": () => import("./../../../src/templates/ServiceTop.tsx" /* webpackChunkName: "component---src-templates-service-top-tsx" */)
}

