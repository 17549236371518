import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  value: any
  children: React.ReactNode
}

export interface PageContextType {
  lang: string
  langs: string[]
  originalPath: string
}

const PageContext: React.Context<PageContextType> = React.createContext({
  lang: 'ja',
  langs: ['ja'],
  originalPath: '/'
})

export const usePageContext = () => React.useContext(PageContext)

export const PageContextProvider = ({ value, children }: Props) => {
  const { i18n } = useTranslation()

  if (i18n.language !== value.lang) {
    i18n.changeLanguage(value.lang)
  }

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}
