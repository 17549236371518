import React from 'react'

export const useSessionStorage = (key: string, initialValue: string) => {
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      const item = sessionStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (e) {
      return initialValue
    }
  })

  React.useEffect(() => {
    try {
      const serializedValue = JSON.stringify(storedValue)
      sessionStorage.setItem(key, serializedValue)
    } catch (e) {
      //
    }
  }, [key, storedValue])

  return [storedValue, setStoredValue]
}
