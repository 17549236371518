import React from 'react'
import { digest, Algorithm } from 'helper'
import { useSessionStorage } from './sessionStorage'

const CORRECT_PASSWORD =
  'f08d5d271f964f73e5424f15c6e1d5a6e58214d82d8abb67b5df6670d57eedaa'

export const useSimpleAuthenticator = () => {
  const [authenticated, setAuthenticated] = React.useState(false)
  const [storedValue, setStoredValue] = useSessionStorage('password', '')

  React.useEffect(() => {
    const env = process.env.ENV || process.env.NODE_ENV || 'development'
    if (env !== 'test' && env !== 'stg') {
      setAuthenticated(true)
      return
    }

    if (storedValue === CORRECT_PASSWORD) {
      setAuthenticated(true)
      return
    }

    const result = window.prompt('パスワードを入力してください。')
    digest(Algorithm.SHA256, '', result).then((sign) => {
      if (sign === CORRECT_PASSWORD) {
        setAuthenticated(true)
        setStoredValue(sign)
      }
    })
  }, [setAuthenticated, setStoredValue])

  return authenticated
}
