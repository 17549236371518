export const Algorithm = {
  SHA1: 'SHA-1',
  SHA256: 'SHA-256',
  SHA384: 'SHA-384',
  SHA512: 'SHA-512'
} as const
export type Algorithm = (typeof Algorithm)[keyof typeof Algorithm]

const hex = (buf: ArrayBuffer): string => {
  var b = new Uint8Array(buf)
  return Array.prototype.map
    .call(b, (x) => x.toString(16).padStart(2, '0'))
    .join('')
}

export const digest = async (
  algorithm: Algorithm,
  secret: string,
  data: string
): Promise<string> => {
  const enc = new TextEncoder()
  const encoded = enc.encode(data)

  if (secret.length === 0) {
    const signature = await crypto.subtle.digest(algorithm, encoded)
    return hex(signature)
  }

  const key = await crypto.subtle.importKey(
    'raw', // raw format of the key - should be Uint8Array
    enc.encode(secret),
    {
      // algorithm details
      name: 'HMAC',
      hash: { name: algorithm }
    },
    false, // export = false
    ['sign', 'verify'] // what this key can do
  )
  const signature = await crypto.subtle.sign('HMAC', key, encoded)
  return hex(signature)
}
