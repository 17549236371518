import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// import language settings
import translation_en from './en.json'
import translation_ja from './ja.json'
import translation_ko from './ko.json'
import translation_zh_cmn_hans from './zh-cmn-hans.json'
import translation_zh_cmn_hant from './zh-cmn-hant.json'

// zh-Hans,zh-HantはHを小文字にすると読み込まなくなるので注意
const resources = {
  ja: {
    translation: translation_ja
  },
  en: {
    translation: translation_en
  },
  ko: {
    translation: translation_ko
  },
  'zh-cmn-Hans': {
    translation: translation_zh_cmn_hans
  },
  'zh-cmn-Hant': {
    translation: translation_zh_cmn_hant
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ja',
    fallbackLng: 'ja',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: false
    }
  })

export const config = i18n
