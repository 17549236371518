import clsx from 'clsx'
import React from 'react'
import { Link } from '@sakura-ui/core'

export interface BreadcrumbsItem {
  id: number
  title: string
  href?: string
}

export type BreadcrumbsProps = React.ComponentProps<'nav'> & {
  items?: BreadcrumbsItem[]
}

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { className, items, ...newProps } = props

  if (items === undefined || items.length === 0) {
    return <></>
  }

  const style = `
    text-sm
  `
  const styleLine = `
    inline
    after:content-[url('/icons/breadcrumbs_separator.svg')]
    after:py-0.5
    after:px-3
    last:after:hidden
  `

  return (
    <nav className={clsx(style, className)} {...newProps}>
      <ol>
        {items.map((item) => (
          <li key={item.id} className={styleLine}>
            {item.href != null ? (
              <Link href={item.href}>{item.title}</Link>
            ) : (
              <>{item.title}</>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}
