import type { GatsbyBrowser } from 'gatsby'
import './src/styles/globals.css'

export { wrapRootElement, wrapPageElement } from './gatsby-ssr'

const DEFAULT_LEFT_OFFSET = 0
const OFFSET_TOP_GUTTER = 20

const getTargetYOffset = (hash: string) => {
  const id = decodeURI(hash.replace('#', ''))
  if (id === '') {
    return null
  }

  const element = document.getElementById(id)
  if (!element) {
    return null
  }

  return element.offsetTop - OFFSET_TOP_GUTTER
}

const scrollToYPosition = (offset: number) => {
  requestAnimationFrame(() => {
    window.scrollTo({
      left: DEFAULT_LEFT_OFFSET,
      top: offset,
      behavior: 'instant'
    })
  })
}

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location }
}) => {
  console.log(`${location.hash}`)
  // gatsby with reach router has a known scrolling issue where it doesn't work.
  // this fixes that problem.
  if (location.hash) {
    const offset = getTargetYOffset(location.hash)
    if (offset !== null) {
      setTimeout(() => {
        scrollToYPosition(offset)
      }, 100)
      return [0, offset]
    }
  }

  return true
}
