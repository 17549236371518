import React from 'react'
import clsx from 'clsx'
import { H2, Link } from '@sakura-ui/core'
import { BreadcrumbsItem, Breadcrumbs } from '../molecules'
import { FooterLogo } from '../organisms'
import { UserInsight } from '../atoms'
import { useSimpleAuthenticator } from '../hooks'

export interface GlobalLayoutProps {
  title: string
  items?: BreadcrumbsItem[]
  children: React.ReactNode
}

export const GlobalLayout = ({ title, items, children }: GlobalLayoutProps) => {
  const authenticated = useSimpleAuthenticator()
  if (!authenticated) {
    return <></>
  }

  const style = `
    min-h-screen
    text-sumi-900
    text-base
    font-medium
    leading-8
  `

  const containerStyle = `
    max-w-xs
    sm:max-w-screen-sm
    md:max-w-screen-md
    lg:max-w-screen-lg
    xl:max-w-[1120px]
    mx-auto
  `

  const breadcrumbsStyle = `
    mt-24
    rounded-3xl
    py-2
    px-4
    bg-sumi-100
  `

  const naviStyle = `
    inline-block
    px-4
  `

  const linkStyle = `
    text-sumi-900
    hover:text-sumi-600
    active:text-sumi-600
    visited:text-sumi-900
  `

  if (!authenticated) {
    return <></>
  }

  return (
    <div className={style}>
      <header className={clsx('max-w-[1120px] mx-auto')}>
        <div className="p-6 xl:px-0">
          <a href="/">
            <h1>
              <img
                className="inline-block mb-1"
                src="/digital-agency-logo.svg"
                alt="デジタル庁"
                width="110"
              />
              <span className="text-small ml-2">
                ウェブサービス・アプリケーション
              </span>
            </h1>
          </a>
        </div>
      </header>
      <main className={containerStyle}>
        <article>
          {title !== '' ? <H2>{title}</H2> : ''}
          {children}
          {items ? (
            <Breadcrumbs className={breadcrumbsStyle} items={items} />
          ) : (
            ''
          )}
        </article>
      </main>
      <footer className="mt-24 text-base-sm">
        <p className="text-center text-base !font-bold">
          <a href="/">ウェブサービス・アプリケーション一覧</a>
        </p>
        <nav className="my-12 text-center text-small">
          <ul className="flex flex-col sm:block gap-4">
            <li className={naviStyle}>
              <Link className={linkStyle} href="/site-policy/">
                サイトポリシー
              </Link>
            </li>
            <li className={naviStyle}>
              <Link
                className={linkStyle}
                href="https://www.digital.go.jp/privacy-policy"
              >
                プライバシーポリシー
              </Link>
            </li>
            <li className={naviStyle}>
              <Link className={linkStyle} href="/accessibility-statement/">
                ウェブアクセシビリティ
              </Link>
            </li>
            <li className={naviStyle}>
              <Link className={linkStyle} href="/copyright-policy/">
                コピーライトポリシー
              </Link>
            </li>
            <li className={naviStyle}>
              <Link
                className={linkStyle}
                href="https://www.digital.go.jp/contact"
              >
                ご意見・ご要望
              </Link>
            </li>
          </ul>
        </nav>
        <FooterLogo />
      </footer>
      <UserInsight />
    </div>
  )
}
